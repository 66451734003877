import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, Method } from 'axios'
import { updateQueryStringParameter } from '../../helpers/http-query-param'
import { ISession } from '../../models'
import { AuthenticationService } from '.'

const apiUrl = process.env.REACT_APP_API_URL

export abstract class BaseService {
  public axiosInstance: AxiosInstance
  private boundResponse = this.handleResponse.bind(this)
  private boundResponseError = this.handleResponseError.bind(this)

  constructor() {
    this.axiosInstance = axios.create({
      auth: { username: 'valudio', password: 'Xilofono23%' }
    })
    this.axiosInstance.interceptors.response.use(this.boundResponse, this.boundResponseError)
  }

  public async get(endpoint: string, session?: ISession): Promise<any> {
    return this.buildRequest(endpoint, null, session)
  }

  public async post(endpoint: string, body: any, session?: ISession): Promise<any> {
    return this.buildRequest(endpoint, 'POST', body, session)
  }

  public async put(endpoint: string, body?: any, session?: ISession): Promise<any> {
    return this.buildRequest(endpoint, 'PUT', body, session)
  }

  public async delete(endpoint: string, session?: ISession): Promise<any> {
    return this.buildRequest(endpoint, 'DELETE', null, session)
  }

  public async patch(endpoint: string, body?: any, session?: ISession): Promise<any> {
    return this.buildRequest(endpoint, 'PATCH', body, session)
  }

  private async buildRequest(endpoint: string, method: Method = 'GET', body?: any, session?: ISession): Promise<any> {
    const hasCredentials = !!session
    const { userName, guid } = hasCredentials && session
    const config: AxiosRequestConfig = {
      method,
      baseURL: apiUrl,
      headers: {
        'Content-Type': 'application/json'
      }
    }

    switch (method) {
      case 'PUT':
        return this.axiosInstance.put(endpoint, body, config)
      case 'POST':
        if (hasCredentials) body = { ...body, userId: userName, guid }
        return this.axiosInstance.post(endpoint, body, config)
      case 'DELETE':
        return this.axiosInstance.delete(endpoint, config)
      case 'PATCH':
        return this.axiosInstance.patch(endpoint, body, config)
      default:
        if (hasCredentials)         {
          endpoint = updateQueryStringParameter(endpoint, 'userId', userName)
          endpoint = updateQueryStringParameter(endpoint, 'guid', guid)
        }
        return this.axiosInstance.get(endpoint, config)
    }
  }

  private handleResponse(response: any): AxiosResponse<any> {
    return response.data
  }

  private async handleResponseError(error: AxiosError, session?: ISession): Promise<AxiosError | any> {
    if (error.response && error.response.status === 401) {
      const hasCredentials = !!session
      const { userName, guid } = hasCredentials && session
      console.log('Signing out')
      await AuthenticationService.signOut(userName, guid)
      const errorMsg = 'User credentials invalid'
      throw errorMsg
    } else {
      throw error.response && error.response.statusText ? error.response.statusText : error.message
    }
  }
}
