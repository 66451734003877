import { Button, Field, Icon, Input } from '@valudio/ui'
import React, { useState, useContext, useEffect, KeyboardEvent } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { LanguageContext, SessionContext, ThemeContext } from '../../context'
import { formatTheme } from '../../helpers/session'
import { useAuth } from '../../hooks'
import { urls } from '../../routing'
import { LocalStorageService } from '../../services'
import Styled from './styles'

const SignIn: React.FC = () => {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const { session, setSession } = useContext(SessionContext)
  const { theme, setTheme } = useContext(ThemeContext)
  const { setLocale } = useContext(LanguageContext)
  const { signIn } = useAuth()
  const [ username, setUsername ] = useState('')
  const [ password, setPassword ] = useState('')
  const [ domain, setDomain ] = useState('')
  const [ error, setError ] = useState<Error>(null)

  const handleUsernameChange = (username: string): void => {
    setUsername(username)
    setError(null)
  }

  const handlePasswordChange = (password: string): void => {
    setPassword(password)
    setError(null)
  }


  const handleDomainChange = (domain: string): void => {
    setDomain(domain)
    setError(null)
  }

  const handleSignIn = async () => {
    const userDomain = domain || theme.domain

    try {
      const authSession = await signIn(username, userDomain, password)
      if (!authSession) throw new Error('No session provided')
      LocalStorageService.set('session', JSON.stringify(authSession))
      setSession(authSession)
      setLocale(authSession.language)
      setTheme(formatTheme(authSession.theme))
      navigate(urls.home)
    } catch (error) {
      console.error(error)
      setError(error as Error)
    }
  }

  const handleKeyPress = async (event: KeyboardEvent<HTMLInputElement>) => {
    if (username && password && event.code === 'Enter') {
      await handleSignIn()
    }
  }

  const errorMessage = () => {
    if (!error) return null
    return (
      <div className="error">
        <Icon icon="attention" className="icon" />
        <span>{ error.message }</span>
      </div>
    )
  }

  const logo = () => {
    const themelogo = theme && theme.logo

    if (!theme) return null
    if (themelogo) return <img src={ themelogo } alt="Logo"/>
    return <img src="assets/images/logo-redsol.svg" alt="Redsol"/>
  }

  const domainField = () => {
    const domain = theme && theme.domain
    if (domain) return null
    return (
      <Field label={ formatMessage({ id: 'domain'  }) } className="field">
        <Input
          className="input"
          placeholder={ formatMessage({ id: 'introduceDomain'  }) }
          onChange={ handleDomainChange }
        />
      </Field>
    )
  }

  useEffect(() => {
    if (!theme) navigate(urls.splash)
    if (session) navigate(urls.home)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ session ])

  return (
    <Styled>
      <section className="container">
        <div className="column form" onKeyDown={ handleKeyPress }>
          <header className="logo">
            { logo() }
          </header>
          <Field label={ formatMessage({ id: 'name'  }) } className="field">
            <Input
              className="input"
              placeholder={ formatMessage({ id: 'introduceName'  }) }
              onChange={ handleUsernameChange }
            />
          </Field>
          <Field label={ formatMessage({ id: 'password'  }) } className="field">
            <Input
              className="input"
              type="password"
              placeholder={ formatMessage({ id: 'introducePassword'  }) }
              onChange={ handlePasswordChange }
            />
          </Field>
          { domainField() }
          { errorMessage() }
          <Button className="button" onClick={ handleSignIn } isDisabled={ !!error }>
            { formatMessage({ id: 'login' }) }
          </Button>
        </div>
        <div className="column image" />
      </section>
    </Styled>
  )
}

export default SignIn

