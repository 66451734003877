import { Icon } from '@valudio/ui'
import React, { PropsWithChildren } from 'react'
import Styled from './styles'

interface IProps {
  isHidden?: boolean
  onClick?: () => void
}

const PageHeader: React.FC<PropsWithChildren<IProps>> = ({ children, isHidden, onClick }) => {
  if (isHidden) return null

  return (
    <Styled>
      <Icon className="logo" icon="menu" onClick={ onClick }/>
      <h2 className="title">{ children }</h2>
    </Styled>
  )
}

export default PageHeader
