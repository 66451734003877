import { formatArrayParam } from '../helpers/http-query-param'
import { Entity, Partner } from '../models'
import { useAxios } from './useAxios'

export const useFilter = () => {
  const { handleRequest, error, loading } = useAxios()
  const getFilterData = async (entities?: Entity[], partners?: Partner[]): Promise<any> => {
    return await handleRequest(
      `filters?${ formatArrayParam(entities, 'entity') }${ formatArrayParam(partners, 'partner') }`,
      'GET'
    )
  }
  return { getFilterData, error, loading }
}
