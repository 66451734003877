import React, { useContext, useState } from 'react'
import { NotificationContext } from '../../../context'
import { useTracks } from '../../../hooks'
import { IFile, ITrackFile } from '../../../models'
import { downloadBase64 } from '../../../helpers/configuration'
import FileModal from '../FileModal'
import FilePreview from '../FilePreview'
import Styled from './styles'

interface IProps {
  trackFile: ITrackFile
  sender: string
  receiver: string
  className?: string
  isDisabled?: boolean
}

const TrackFile: React.FC<IProps> = ({ trackFile, sender, receiver, className, isDisabled }) => {
  const { setNotification } = useContext(NotificationContext)
  const { fetchFile, fetchFilePdfReport } = useTracks()
  const [ file, setFile ] = useState<IFile>(null)
  const [ fileReport, setFileReport ] = useState<IFile>(null)
  const [ isModalVisible, setIsModalVisible ] = useState(false)
  const [ isReportModalVisible, setIsReportModalVisible ] = useState(false)
  const [ isMobilePreviewVisible, setIsMobilePreviewVisible ] = useState(false)
  const isMobileView = window.screen.width <= 736
  const isFileDataEmpty = !file || !file.data

  const handleFetchFile = async () => {
    if (file) return
    try {
      const result = await fetchFile(trackFile.fileId)
      setFile(result)
      if (trackFile.pdfAvailable) {
        const resultReport = await fetchFilePdfReport(trackFile.fileId)
        setFileReport(resultReport)
      }
    } catch (error) {
      console.error(error)
      setNotification((error as Error).message)
    }
  }

  const handleMouseOver = async () => {
    if (isMobileView) return
    await handleFetchFile()
  }

  const handleClick = async () => {
    if (isFileDataEmpty && !isMobileView) return
    else if (isFileDataEmpty && isMobileView) await handleFetchFile()
    setIsModalVisible(true)
  }

  const handleReportClick = async () => {
    if (isFileDataEmpty && !isMobileView) return
    else if (isFileDataEmpty && isMobileView) await handleFetchFile()
    setIsReportModalVisible(true)
  }

  const handleDownloadFileReport = () => {
    downloadBase64(fileReport)
  }

  const handleModalClose = () => {
    if (isFileDataEmpty) return
    setFile(null)
    setIsModalVisible(false)
    setIsMobilePreviewVisible(false)
  }

  const handleReportModalClose = () => {
    if (isFileDataEmpty) return
    setIsReportModalVisible(false)
    setIsMobilePreviewVisible(false)
  }

  const handleOpenFilePreview = () => {
    if (isMobileView) setIsMobilePreviewVisible(!isMobilePreviewVisible)
  }

  return (
    <Styled onMouseOver={ handleMouseOver } className={ className || '' }>
      <div className="file exchange">
        <img src={ trackFile.icon } alt={ trackFile.extension }/>
      </div>
      <i className="status-badge" style={{ backgroundColor: `${ trackFile.status.value.color }` }}/>
      <FileModal
        isHidden={ !isModalVisible || isFileDataEmpty }
        file={ file }
        format={ trackFile.format }
        onClose={ handleModalClose }
        onOpenFileDetail={ handleOpenFilePreview }
      />
      <FileModal
        isHidden={ !isReportModalVisible || isFileDataEmpty }
        file={ fileReport }
        format={ trackFile.format }
        onClose={ handleReportModalClose }
      />
      <FilePreview
        className={`preview ${ isMobileView ? 'mobile' : '' }`}
        trackFile={ trackFile }
        file={ file }
        fileReport={ fileReport }
        sender={ sender }
        receiver={ receiver }
        isHidden={ isDisabled || isFileDataEmpty || (isMobileView && !isMobilePreviewVisible) }
        onClose={ handleOpenFilePreview }
        onOpenClick={ handleClick }
        onOpenPdf={ handleReportClick }
        onDownloadPdf={ handleDownloadFileReport }
      />
    </Styled>
  )
}

export default TrackFile
