import styled from 'styled-components'

export default styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  
  header {
    margin-bottom: 2px;
    h4 {
      margin: 0;
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
    }
  }
  
  .steps-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 0;
  }
`
