import styled from 'styled-components'

export default styled.div`
  .label {
    font-size: 14px;
    color: ${ ({ theme }) => theme.colors.typography.medium };
    margin-bottom: 6px;
  }

  .input {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    max-width: 100%;
    height: 40px;
    background: ${ ({ theme }) => theme.colors.grey.light };
    color: ${ ({ theme }) => theme.colors.typography.light };
    font-size: 14px;
    padding: 0 8px 0 12px;
    border-radius: 2px;
    border: 1.5px solid transparent;
    box-sizing: border-box;
    cursor: pointer;

    &:focus {
      border: 1px solid ${ ({ theme }) => theme.colors.primary.medium };
      background: ${ ({ theme }) => theme.colors.white };
      color: ${ ({ theme }) => theme.colors.typography.medium };
    }

    &:invalid {
      border: 1px solid ${ ({ theme }) => theme.colors.system.error };
      color: ${ ({ theme }) => theme.colors.system.error };
    }
  }

  input[type="date"]::-webkit-inner-spin-button {
    height: 40px;
  }
`
