import { ChangePassword, Home, SignIn, Splash } from './pages'

export const urls = {
  splash: '/',
  signIn: '/sign-in',
  home: '/home',
  changePassword: '/change-password'
}

export const routes = [
  { url: urls.splash, content: Splash, isPrivate: false },
  { url: urls.signIn, content: SignIn, isPrivate: false },
  { url: urls.home, content: Home, isPrivate: true },
  { url: urls.changePassword, content: ChangePassword, isPrivate: true }
]
