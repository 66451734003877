import { formatArrayParam, formatParam } from '../../helpers/http-query-param'
import { Entity, IFile, MessageType, Partner, Status, TrackPage } from '../../models'
import { BaseService } from './base'

class TrackService extends BaseService {
  public fetchTrackPage(
    entities?: Entity[],
    partners?: Partner[],
    messageType?: MessageType[],
    status?: Status[],
    subPartners?: Partner[],
    messageRef?: string,
    dateFrom?: string,
    dateTo?: string,
    orderBy?: string,
    order?: string,
    hideTracks?: boolean,
    pageNumber = 1,
    records = 20
  ): Promise<TrackPage> {
    return this.get(
      `tracks?page=${ pageNumber }&records=${ records }` +
      `${ formatArrayParam(entities, 'entity') }` +
      `${ formatArrayParam(partners, 'partner') }` +
      `${ formatArrayParam(messageType, 'messageType') }` +
      `${ formatArrayParam(status, 'status') }` +
      `${ formatArrayParam(subPartners, 'subPartner') }` +
      `${ formatParam(messageRef, 'messageRef') }` +
      `${ formatParam(dateFrom, 'dateFrom') }` +
      `${ formatParam(dateTo, 'dateTo') }` +
      `${ formatParam(orderBy, 'orderBy') }` +
      `${ formatParam(order, 'order') }` +
      `${ formatParam(hideTracks.toString(), 'hideTracks') }`
    )
  }

  public changeTrackVisibility(trackId: string): Promise<void> {
    return this.post('changetrackvisibility', { trackId })
  }

  public fetchFile(fileId: string): Promise<IFile> {
    return this.get(`filedata?${ formatParam(fileId, 'fileId') }`)
  }
}

export default new TrackService()
