import styled from 'styled-components'

export default styled.article`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  flex: 1;
  overflow-y: auto;

  + .menu-overlay {
    display: none;
  }

  .container {
    display: flex;
    flex-direction: column;
    padding: 14px 32px 32px;
    box-sizing: border-box;
    position: relative;

    .field {
      margin-bottom: 14px;
    }
  }
`
