import React, { PropsWithChildren } from 'react'
import Styled from './styles'

interface IProps {
  label: string
  className?: string
}

const Field: React.FC<PropsWithChildren<IProps>> = ({ label, children, className }) => {
  return (
    <Styled className={ className || '' }>
      <span className="label">{ label }</span>
      <span className="value">{ children }</span>
    </Styled>
  )
}

export default Field
