import Pdf from '@mikecousins/react-pdf'
import React from 'react'

interface IProps {
  file: string
}

const PdfViewer: React.FC<IProps> = ({ file }) => {

  return <Pdf file={ file } />
}

export default PdfViewer
