import styled from 'styled-components'

export default styled.div`
  .frame {
    max-width: 80vw;
    min-width: 70vw;
    height: 100%;
  }

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 8px;
    margin: 0 0 12px;

    .file {
      flex: 0 1 auto;
      
      .icon {
        margin-right: 8px;
      }
    }

    .download {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-basis: 40px;
      width: 40px;
      height: 40px;
      border: 1px solid ${ ({ theme }) => theme.colors.typography.medium };
      font-size: 22px;
      color: ${ ({ theme }) => theme.colors.typography.medium };
      border-radius: 50%;
      margin: 0 16px;
    }

    .close {
      font-size: 24px;
      color: ${ ({ theme }) => theme.colors.typography.medium };
    }

    .back,
    .info {
      display: none;
    }
  }

  .content {
    border: 1px solid ${ ({ theme }) => theme.colors.grey.dark };
    overflow-y: auto;
    height: calc(100% - 48px);

    .txt {
      font-family: ${ ({ theme }) => theme.fontFamily };
      margin: 16px;
      font-size: 14px;
      word-break: break-all;
      color: ${ ({ theme }) => theme.colors.typography.dark };
      white-space: pre;
    }

    .pdf {
      width: 100%;
      height: 100%;
    }

    .img {
      width: 100%;
      height: auto;
    }
  }

  @media (max-width: 736px) {
    .frame {
      width: 100vw;
      max-width: 100vw;
      height: 100vh;
      max-height: 100vh;
      box-sizing: content-box;
      box-shadow: none;

      .content {
        border-bottom: 0;
        border-left: 0;
        border-right: 0;
        max-height: calc(100% - 38px);
        box-sizing: border-box;
      }

      .header {
        flex-wrap: nowrap;
        justify-content: space-between;

        .file {
          flex: 1;
        }
        .download {
          flex: 0 0 40px;
          margin-left: 8px;
          margin-right: 0;
        }
        .info {
          display: flex;
          justify-content: center;
          align-items: center;
          flex: 0 0 40px;
          height: 40px;
          border: 1px solid ${ ({ theme }) => theme.colors.typography.medium };
          font-size: 22px;
          color: ${ ({ theme }) => theme.colors.typography.medium };
          border-radius: 50%;
          margin-left: 12px;
        }
        .close {
          display: none;
        }
        .back {
          display: inline-block;
          flex: 0 0 24px;
          margin-right: 8px;
          font-size: 24px;
          color: ${ ({ theme }) => theme.colors.typography.medium };
          text-align: center;
        }
      }
    }
  }
`
