import { File, FileType, Icon, Modal } from '@valudio/ui'
import React from 'react'
import { downloadBase64, isOldEdgeOrAndroid } from '../../../helpers/configuration'
import { dataToEdifactContent, extensionToIconExtension } from '../../../helpers/file'
import { IFile } from '../../../models'
import { PdfViewer } from '../../shared'
import Styled from './styles'

interface IProps {
  file: IFile
  format: string
  onClose: () => void
  onOpenFileDetail?: () => void
  isHidden?: boolean
}

const FileModal: React.FC<IProps> = ({ file, format, onClose, onOpenFileDetail, isHidden }) => {
  let content
  const isMobileView = window.screen.width <= 736
  const extension = !!file && extensionToIconExtension(file.extension, format)

  switch (extension) {
    case 'txt':
    case 'idoc':
    case 'xml':
    case 'csv':
      content = <p className="txt">{ atob(file.data) }</p>
      break
    case 'pdf':
      if (isOldEdgeOrAndroid() || isMobileView) {
        content = <PdfViewer file={ `data:application/pdf;base64,${ file.data }` }/>
      } else {
        content = (
          <object className="pdf" data={ `data:application/pdf;base64,${ file.data }` } type="application/pdf">
            .pdf not supported
          </object>
        )
      }
      break
    case 'html':
      content = <div className="html" dangerouslySetInnerHTML={ { __html: atob(file.data) } } />
      break
    case 'edi':
      content = dataToEdifactContent(file.data)
      break
    case 'img':
      content = <img className="img" src={ `data:image/png;base64,${ file.data }` } alt={ file.name } />
      break
    default:
      content = null
  }

  if (isHidden) return null
  return (
    <Styled>
      <Modal isHidden={ isHidden } onOverlayClick={ onClose }>
        <section className="header">
          <Icon icon="left" className="back" onClick={ onClose } />
          <File
            name={ file.name }
            className="file"
            type={ extension as FileType }
            style={ { marginRight: 'auto', width: 'auto', padding: '0 12px' } }
          />
          <Icon icon="info" onClick={ onOpenFileDetail }/>
          <Icon icon="download" className="download" onClick={ downloadBase64.bind(undefined, file) } />
          <Icon icon="close" className="close" onClick={ onClose } />
        </section>
        { !!content && <section className="content">{ content }</section> }
      </Modal>
    </Styled>
  )
}

export default FileModal
