import { DateTime } from '@valudio/ui'
import React from 'react'
import { useIntl } from 'react-intl'
import Styled from './styles'

interface IProps {
  label: string
  minDate?: Date
  onChange: (value: Date) => void
  initialValue?: Date
}

const DateTimeFilter: React.FC<IProps> = ({ label, onChange, minDate, initialValue }) => {
  const { formatMessage } = useIntl()

  return (
    <Styled className="filter date-filter">
      <label className="label">{ label }</label>
      <DateTime
        initialValue={ initialValue }
        onChange={ onChange }
        className="input datepicker"
        minDate={ minDate }
        placeholder={ formatMessage({ id: 'datePlaceholder' }) }
        showTime={ false }
      />
    </Styled>
  )
}

export default DateTimeFilter
