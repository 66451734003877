import React, { createContext, PropsWithChildren, useEffect, useState } from 'react'
import { ISession } from '../models'
import { LocalStorageService } from '../services'

export interface ISessionContext {
  session: ISession | null
  setSession: (session: ISession | null) => void
}

const SessionContext = createContext<ISessionContext>({
  session: null,
  setSession: () => undefined
})

const SessionProvider: React.FC<PropsWithChildren<any>> = ({ children }) => {
  const [ session, setSession ] = useState<ISession>(null)

  useEffect(() => {
    const activeSession = LocalStorageService.get('session')
    if (activeSession) {
      setSession(activeSession)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <SessionContext.Provider
      value={{ session, setSession }}
    >
      { children }
    </SessionContext.Provider>
  )
}

export { SessionContext, SessionProvider }
