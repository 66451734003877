import { Icon, IconName, ITableColumn, Pagination, Spinner, Table } from '@valudio/ui'
import React from 'react'
import { useIntl } from 'react-intl'
import { formatDate } from '../../../helpers/date'
import { TrackPage } from '../../../models'
import { StatusSteps } from '../index'
import Exchanges from './Exchanges'
import Styled from './styles'

interface IProps {
  page: TrackPage
  columns: ITableColumn[]
  onChangeTrackVisibility: (trackId: string) => void
  onPageChange: (pageNumber: number) => void
  isLoading: boolean
}

const TracksTable: React.FC<IProps> = ({ page, columns, onChangeTrackVisibility, onPageChange, isLoading }) => {
  const { formatMessage } = useIntl()

  const handleTrackVisibilityChange = (trackId: string) => {
    if (onChangeTrackVisibility) onChangeTrackVisibility(trackId)
  }

  const trackRows = page && page.items.map(x => {
    const iconClassNames = `archive-icon ${ !!x.visible && 'visible' }`
    const trackingFiles = x.trackings.map(file => {
      const extension = file.extension.toLowerCase()
      if (extension === 'sap' || extension === 'idoc') {
        file.extension = 'sap'
      } else if (extension === 'excel' || extension === 'xls' || extension === 'csv') {
        file.extension = 'excel'
      } else if (
        extension === 'jpg' ||
        extension === 'jpeg' ||
        extension === 'png' ||
        extension === 'tif' ||
        extension === 'tiff'
      ) {
        file.extension = 'img'
      }
      return file
    })
    const inbounds = trackingFiles.filter(tf => tf.type === 'inbound')
    const outbounds = trackingFiles.filter(tf => tf.type !== 'inbound')

    return ({
      archived: (
        <Icon
          className={ iconClassNames}
          icon={ (x.visible ? 'eye' : 'hide') as IconName }
          onClick={ handleTrackVisibilityChange.bind(undefined, x.trackId) }
        />
      ),
      senderReceiver: (
        <div className="sender-receiver">
          <span>{ x.sender }</span>
          <img
            src="assets/images/arrow-down.svg"
            alt="Down"
          />
          <span>{ x.receiver }</span>
        </div>
      ),
      description: x.description,
      exchanges: (
        <Exchanges sender={ x.sender } receiver={ x.receiver } inbounds={ inbounds } outbounds={ outbounds } />
      ),
      date: (
        <div className="dates">
          <p>
            <span className="date">{ formatDate(x.creationDate) }</span>
            <span>{ formatMessage({ id: 'created' }) }</span>
          </p>
          <p>
            <span className="date">{ formatDate(x.updateDate) }</span>
            <span>{ formatMessage({ id: 'updated' }) }</span>
          </p>
        </div>
      ),
      messageType: x.messageType.value,
      status: <StatusSteps status={ x.status } />
    })
  })

  const pagination = (page && !!page.items.length) && (
    <Pagination
      currentPage={ page.pagination.pageNumber }
      totalPages={ page.pagination.totalPages }
      onClick={ onPageChange }
    />
  )

  if (isLoading) return <Spinner />
  return (
    <Styled>
      <Table
        columns={ columns || [] }
        items={ trackRows || [] }
        fallbackMessage={ formatMessage({ id: 'noTrackings' }) }
      />
      { pagination }
    </Styled>
  )
}

export default TracksTable
