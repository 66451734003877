import React, { createContext, PropsWithChildren, useState } from 'react'

export interface IUiContext {
  isMenuExpanded: boolean
  setIsMenuExpanded: (isExpanded: boolean) => void
}

const UiContext = createContext<IUiContext>({
  isMenuExpanded: true,
  setIsMenuExpanded: () => undefined
})

const UiProvider: React.FC<PropsWithChildren<any>> = ({ children }) => {
  const [ isMenuExpanded, setIsMenuExpanded ] = useState(false)
  return (
    <UiContext.Provider value={{ isMenuExpanded, setIsMenuExpanded }}>
      { children }
    </UiContext.Provider>
  )
}

export { UiProvider, UiContext }
