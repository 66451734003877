import { Icon, IOption, ITableColumn } from '@valudio/ui'
import React, { KeyboardEvent, ReactNode, useContext, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import {
  DateTimeFilter,
  FilterActions,
  Menu,
  MultiSelectFilter,
  PageTitle,
  TextFilter,
  TracksTable
} from '../../components'
import { SessionContext, UiContext, NotificationContext } from '../../context'
import { formatInputDate } from '../../helpers/date'
import { useFilter, useTracks } from '../../hooks'
import { IFilter, TrackPage } from '../../models'
import { urls } from '../../routing'
import Styled from './styles'

const Home: React.FC = () => {
  const isMobileScreen = window.screen.width <= 736
  const initialFromDate = new Date()
  initialFromDate.setUTCMonth(initialFromDate.getUTCMonth() - 0)
  initialFromDate.setUTCHours(0, 0, 0)
  const navigate = useNavigate()
  const { formatMessage } = useIntl()
  const { session } = useContext(SessionContext)
  const { isMenuExpanded, setIsMenuExpanded } = useContext(UiContext)
  const { setNotification } = useContext(NotificationContext)
  const { fetchTrackPage, changeTrackVisibility } = useTracks()
  const { getFilterData } = useFilter()
  const [ page, setPage ] = useState<TrackPage>(null)
  const [ filters, setFilters ] = useState<IFilter>(
    { entities: [], partners: [], messageType: [], status: [], subPartners: [] }
  )
  const [ selectedFilters, setSelectedFilters ] = useState<IFilter>(
    {
      entities: [],
      partners: [],
      messageType: [],
      messageRef: '',
      status: [],
      subPartners: [],
      dateFrom: formatInputDate(initialFromDate.toUTCString())
    }
  )
  const [ areFiltersVisible, setAreFiltersVisible ] = useState(true)
  const [ areArchivedTracksHidden, setAreArchivedTracksHidden ] = useState(false)
  const [ order, setOrder ] = useState('')
  const [ orderBy, setOrderBy ] = useState('')
  const [ isFetching, setIsFetching ] = useState(false)

  const toggleFiltersVisibility = (): void => {
    setAreFiltersVisible(!areFiltersVisible)
  }

  const toggleResponsiveMenuVisibility = (): void => {
    setIsMenuExpanded(!isMenuExpanded)
  }

  const fetchTracks = async (
    orderBy?: string, order?: string, hideTracks?: boolean, pageNumber?: number
  ): Promise<void> => {
    const isMobileScreen = window.screen.width <= 736
    setIsFetching(true)

    try {
      const sholdHideTracks = typeof hideTracks === 'boolean' ? hideTracks : areArchivedTracksHidden
      if (isMobileScreen && !areFiltersVisible) toggleFiltersVisibility()

      const trackPage = await fetchTrackPage(
        selectedFilters.entities,
        selectedFilters.partners,
        selectedFilters.messageType,
        selectedFilters.status,
        selectedFilters.subPartners,
        selectedFilters.messageRef,
        selectedFilters.dateFrom ? (new Date(selectedFilters.dateFrom)).toISOString() : null,
        selectedFilters.dateTo ? (new Date(selectedFilters.dateTo)).toISOString() : null,
        orderBy,
        order,
        sholdHideTracks,
        pageNumber
      )
      setPage(trackPage)
    } catch (error) {
      console.error(error)
      setNotification((error as Error).message)
    } finally {
      setIsFetching(false)
    }
  }

  const fetchFilters = async (selectedFilters: IFilter): Promise<void> => {
    try {
      const { entities, partners } = selectedFilters
      const filters = await getFilterData(entities, partners)
      setFilters(filters)
    } catch (error) {
      console.error(error)
      setNotification((error as Error).message)
    }
  }

  const handleFilterChange = async (key: string, value: any): Promise<void> => {
    let updateFilter
    switch (key) {
      case 'dateTo':
      case 'dateFrom':
      case 'messageRef':
        updateFilter = { [key]: value }
        break
      case 'entities':
        updateFilter = {
          [key]: value.map((v: IOption) => ({ key: v.id, value: v.label })),
          partners: [],
          subPartners: []
        }
        break
      case 'partners':
        updateFilter = {
          [key]: value.map((v: IOption) => ({ key: v.id, value: v.label })),
          subPartners: []
        }
        break
      default:
        updateFilter = { [key]: value.map((v: IOption) => ({ key: v.id, value: v.label })) }
    }

    const updatedSelectedFilters = { ...selectedFilters, ...updateFilter }
    setSelectedFilters(updatedSelectedFilters)
    if (key === 'entities' || key === 'partners') await fetchFilters(updatedSelectedFilters)
  }

  const handleColumnSort = async (key: string): Promise<void> => {
    try {
      const column = tableColumns.find(c => c.key === key)
      let updatedOrderBy

      if (!!column.sort && column.sort === 'INACTIVE') {
        column.sort = 'ASC'
        updatedOrderBy = key
      } else if (column.sort) {
        column.sort = column.sort === 'ASC' ? 'DESC' : 'INACTIVE'
        updatedOrderBy = column.sort !== 'INACTIVE' && key
      }

      const updatedOrder = column.sort !== 'INACTIVE' && column.sort
      setOrder(updatedOrder)
      setOrderBy(updatedOrderBy)
      await fetchTracks(updatedOrderBy, updatedOrder)
    } catch (error) {
      console.error(error)
    }
  }

  const handlePageChange = async (pageNumber: number): Promise<void> => {
    await fetchTracks(orderBy, order, areArchivedTracksHidden, pageNumber)
  }

  const handleChangeTrackVisibility = async (trackId: string): Promise<void> => {
    try {
      const track = page.items.find(i => i.trackId === trackId)
      const currentVisibility = track.visible
      const updatedTracks = [ ...page.items ]
      await changeTrackVisibility(trackId)

      updatedTracks.forEach(i => i.visible = i.trackId === trackId ? !currentVisibility : i.visible)
      setPage({ pagination: page.pagination, items: updatedTracks })
    } catch (error) {
      console.error(error)
      setNotification((error as Error).message)
    }
  }

  const handleChangeArchiveVisibility = async (): Promise<void> => {
    await fetchTracks(null, null, !areArchivedTracksHidden, page.pagination.pageNumber)
    setAreArchivedTracksHidden(!areArchivedTracksHidden)
  }

  const handleKeyPress = async (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.code === 'Enter') {
      await fetchTracks()
    }
  }

  const tableColumns: ITableColumn[] = [
    {
      label: '',
      key: 'archived',
      style: { flex: isMobileScreen ? '0 1 30px' : 0.2 },
      isHidden: !!session && !session.hideTracks
    },
    {
      label: '',
      key: 'senderReceiver',
      style: { flex: isMobileScreen ? '0 1 100%' : 1.5 }
    },
    {
      label: formatMessage({ id: 'description' }),
      key: 'description',
      sort: (orderBy === 'description' ? order : undefined) as any,
      style: { flex: isMobileScreen ? '0 1 100%' : 4 },
      onClick: () => handleColumnSort('description')
    },
    {
      label: formatMessage({ id: 'exchanges' }),
      key: 'exchanges',
      style: {
        flex: isMobileScreen ? '0 1 100%' : 1,
        // maxHeight: '120px',
        // overflowY: 'auto',
        maxWidth: '160px',
        minWidth: '160px',
        width: '100%',
        overflow: 'visible',
        justifyContent: 'flex-start'
      }
    },
    {
      label: formatMessage({ id: 'date' }),
      key: 'date',
      sort: (orderBy === 'date' ? order : undefined) as any,
      style: { flex: isMobileScreen ? '0 1 50%' : 1.5 },
      onClick: () => handleColumnSort('date')
    },
    {
      label: formatMessage({ id: 'messageType' }),
      key: 'messageType',
      style: { flex: isMobileScreen ? '0 1 50%' : 2 },
      sort: (orderBy === 'messageType' ? order : undefined) as any,
      onClick: () => handleColumnSort('messageType')
    },
    {
      label: formatMessage({ id: 'status' }),
      key: 'status',
      style: { flex: isMobileScreen ? '0 1 30px' : 1, maxWidth: '120px', flexBasis: '120px' }
    }
  ]

  const isAnyFieldWithError = (): boolean => {
    const errorInputs = document.querySelectorAll('input:invalid')
    return !!errorInputs && !!errorInputs.length
  }

  const tracksCount = (): ReactNode => {
    if (!page || (!!page && !page.pagination.totalItems)) return null
    return (
      <span className="count">
        ( { `${ page.pagination.totalItems } ${ formatMessage({ id: 'tracksFound' }) }` } )
      </span>
    )
  }

  useEffect(() => {
    if (session) {
      fetchTracks(null, null, areArchivedTracksHidden)
      fetchFilters(selectedFilters)
    } else {
      navigate(urls.splash)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!session) navigate(urls.splash)
  }, [ session, navigate ])

  return (
    <>
      <Menu />
      <Styled onKeyDown={ handleKeyPress }>
        <PageTitle onClick={ toggleResponsiveMenuVisibility }>
          { formatMessage({ id: 'trackingList' }) }
          { tracksCount() }
          <Icon
            className="filter-visibility"
            icon={ !areFiltersVisible ? 'close' : 'filter' }
            onClick={ toggleFiltersVisibility }
          />
        </PageTitle>
        <section className={ `filters ${ !areFiltersVisible ? 'closed' : '' }` }>
          <section className="container">
            <MultiSelectFilter
              value={ selectedFilters.entities.map(e => ({ id: e.key, label: e.value })) }
              label={ formatMessage({ id: 'myEntities' }) }
              items={ filters.entities }
              placeholder={ formatMessage({ id: 'selectEntity' }) }
              onChange={ value => handleFilterChange('entities', value) }
            />
            <MultiSelectFilter
              value={ selectedFilters.messageType.map(m => ({ id: m.key, label: m.value })) }
              label={ formatMessage({ id: 'messageType' }) }
              items={ filters.messageType }
              placeholder={ formatMessage({ id: 'selectMessageType' }) }
              onChange={ value => handleFilterChange('messageType', value) }
            />
            <TextFilter
              label={ formatMessage({ id: 'messageRef' }) }
              placeholder={ formatMessage({ id: 'introduceMessageRef' }) }
              onChange={ value => handleFilterChange('messageRef', value) }
            />
            <MultiSelectFilter
              value={ selectedFilters.partners.map(p => ({ id: p.key, label: p.value })) }
              label={ formatMessage({ id: 'partners' }) }
              items={ filters.partners }
              placeholder={ formatMessage({ id: 'selectPartner' }) }
              onChange={ value => handleFilterChange('partners', value) }
            />
            <MultiSelectFilter
              value={ selectedFilters.subPartners.map(s => ({ id: s.key, label: s.value })) }
              label={ formatMessage({ id: 'subPartners' }) }
              items={ filters.subPartners }
              placeholder={ formatMessage({ id: 'selectSubPartner' }) }
              onChange={ value => handleFilterChange('subPartners', value) }
            />
            <DateTimeFilter
              initialValue={ initialFromDate }
              label={ formatMessage({ id: 'dateFrom' }) }
              // minDate={ minFromDate.toISOString() }
              onChange={ value => handleFilterChange('dateFrom', value) }
            />
            <DateTimeFilter
              label={ formatMessage({ id: 'to' }) }
              minDate={ new Date(selectedFilters.dateFrom) }
              onChange={ value => {
                const valueWithHours = value.setUTCHours(23, 59)
                const valueDayCorrected = new Date(valueWithHours).setUTCDate(value.getDate())
                handleFilterChange('dateTo', valueDayCorrected)
              }}
            />
            <MultiSelectFilter
              value={ selectedFilters.status.map(s => ({ id: s.key, label: s.value })) }
              label={ formatMessage({ id: 'status' }) }
              items={ filters.status }
              placeholder={ formatMessage({ id: 'selectStatus' }) }
              onChange={ value => handleFilterChange('status', value) }
            />
          </section>
          <FilterActions
            initialArchiveValue={ !areArchivedTracksHidden }
            onSearch={ fetchTracks }
            onArchiveChange={ handleChangeArchiveVisibility }
            isArchiveHidden={ !!session && !session.hideTracks }
            areActionsDisabled={ isAnyFieldWithError() }
          />
          <Icon
            className="filter-visibility"
            icon={ areFiltersVisible ? 'up' : 'filter' }
            onClick={ toggleFiltersVisibility }
          />
        </section>
        <TracksTable
          page={ page }
          columns={ tableColumns }
          isLoading={ isFetching }
          onChangeTrackVisibility={ handleChangeTrackVisibility }
          onPageChange={ handlePageChange }
        />
      </Styled>
      <div
        className={`menu-overlay ${ isMenuExpanded ? 'visible' : '' }`}
        onClick={ toggleResponsiveMenuVisibility }
      />
    </>
  )
}

export default Home
