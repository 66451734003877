import styled from 'styled-components'

export default styled.div`
  .label {
    font-size: 14px;
    color: ${ ({ theme }) => theme.colors.typography.medium };
    margin-bottom: 6px;
  }

  .input {
    width: 100%;

    > div > div {
      overflow: hidden;
    }
  }

  .jGiNHf table tbody td._1PHXZRv9_4KmFMrk8q46oq {
    background-color: #ffffff !important;
    color: #4a4a4a !important;
  }

  ._2Nt60bozBhLm4MJ7-Uml9R ._1u8Ymm6dfTneTUifGRca6q table tbody tr td._1PHXZRv9_4KmFMrk8q46oq {
    background-color: #ffffff !important;
    color: #4a4a4a !important;
  }

  ._2Nt60bozBhLm4MJ7-Uml9R ._1u8Ymm6dfTneTUifGRca6q table tbody tr td._1PHXZRv9_4KmFMrk8q46oq:hover {
    background-color: #f0f0f0 !important;
    color: #4a4a4a !important;
  }
`
