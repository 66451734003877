import { defaultTheme } from '@valudio/ui'
import { ITheme } from '../models'

export function formatTheme(theme?: any): ITheme {
  return {
    ...defaultTheme,
    logo: theme && theme.logo,
    colors: {
      ...defaultTheme.colors,
      primary: {
        dark: (theme && theme.primaryDark) || defaultTheme.colors.primary.dark,
        light: (theme && theme.primaryLight) || defaultTheme.colors.primary.light,
        medium: (theme && theme.primaryMedium) || defaultTheme.colors.primary.medium
      }
    }
  }
}
