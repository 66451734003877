import { defaultTheme } from '@valudio/ui'
import { IFile, ITheme } from '../models'

export function getSubdomain(): string {
  const host = window.location.hostname === 'localhost'
    ? window.location.host.replace('localhost', 'redstest.example.com')
    : window.location.host
  const hostParts = host.split('.')
  const subdomain = hostParts[0]
  return subdomain
}

export function formatLoginTheme(theme?: any): ITheme {
  return {
    ...defaultTheme,
    logo: theme && theme.logo,
    domain: theme && theme.domain,
    colors: {
      ...defaultTheme.colors,
      primary: {
        dark: (theme && theme.colorPrimaryDark) || defaultTheme.colors.primary.dark,
        light: (theme && theme.colorPrimaryLight) || defaultTheme.colors.primary.light,
        medium: (theme && theme.colorPrimaryMedium) || defaultTheme.colors.primary.medium
      }
    }
  }
}

export function downloadBase64(file: IFile) {
  const contentType = `data:application/${ file.extension }`
  const linkSource = `${ contentType };base64,${ file.data }`
  const downloadLink = document.createElement('a')
  const fileName = `${ file.name }.${ file.extension }`
  const customNavigator: any = window.navigator

  if (customNavigator.msSaveBlob) { // Check for MS browsers (IE, Edge)
    customNavigator.msSaveBlob(base64toBlob(file.data, contentType), fileName)
    return
  }

  // iOS devices do not support downloading. We have to inform user about this.
  if (/(iP)/g.test(navigator.userAgent)) {
    // alert('Your device does not support files downloading. Please try again in desktop browser.');
    window.open(linkSource, '_blank')
    return
  }

  downloadLink.href = linkSource
  downloadLink.download = fileName

  downloadLink.click()
}

export function base64toBlob(b64Data: string, contentType = '', sliceSize = 512) {
  const byteCharacters = atob(b64Data)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  const blob = new Blob(byteArrays, { type: contentType })
  return blob
}

export function isOldEdgeOrAndroid() {
  return navigator.userAgent.toLowerCase().indexOf('edge') > -1
  || navigator.userAgent.toLowerCase().indexOf('android') > -1
}
