import React, { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react'
import { IntlProvider } from 'react-intl'
import { useLanguage } from '../hooks'
import { ILabelResponse, ILanguage, ILiterals } from '../models'
import { literals as fallbackLiterals } from '../literals'
import { SessionContext } from './session'

export interface ILanguageContext {
  locale: string
  messages: any
  languages: ILanguage[]
  literals?: ILiterals
  setLocale: (newLocale: string) => void
  setLanguages: (languages: ILanguage[]) => void
  setLiterals: (literals?: ILiterals) => void
  isInitialized: boolean
  setIsInitialized: (init: boolean) => void
}

const LanguageContext = createContext<ILanguageContext>({
  locale: 'en-US',
  messages: undefined,
  languages: [],
  literals: undefined,
  setLocale: () => undefined,
  setLanguages: () => undefined,
  setLiterals: () => undefined,
  isInitialized: false,
  setIsInitialized: () => undefined
})

const LanguageProvider: React.FC<PropsWithChildren<any>> = ({ children }) => {
  const { session } = useContext(SessionContext)
  const { fetchIntlData } = useLanguage()
  const [ language, setLanguage ] = useState<string>('en-US')
  const [ languages, setLanguages ] = useState([])
  const [ literals, setLiterals ] = useState<ILiterals>()
  const [ messages, setMessages ] = useState(() => literals ? literals['en-US'] : fallbackLiterals['en-US'])
  const [ isInitialized, setIsInitialized ] = useState(false)

  const handleSetLocale = useCallback((newLocale: string) => {
    setLanguage(newLocale)
    setMessages(literals[newLocale] as any)
  }, [ literals ])

  const checkBrowserLanguage = useCallback((): void => {
    const browserLanguage: any = session && session.language
      ? session.language
      : navigator.language || (navigator as any).userLanguage

    if (languages.some(l => l.code === browserLanguage)) {
      handleSetLocale(browserLanguage)
    } else {
      handleSetLocale('en-US')
    }
  }, [ handleSetLocale, languages, session ])

  const fetchLiteralsData = useCallback(async (): Promise<void> => {
    const labelsResponse: ILabelResponse = await fetchIntlData()
    const languages: ILanguage[] = labelsResponse.labels.map(l => {
      return { name: l.name, code: l.code }
    })
    const literals = labelsResponse.labels.map(l => {
      const languageIsoLabels = { ...Object.fromEntries(l.labels.map(label => [ label.label, label.value ])) }
      return [ l.code, languageIsoLabels ]
    })
    setLanguages(languages)
    setLiterals(Object.fromEntries([ ...literals ]))
    setIsInitialized(true)
    checkBrowserLanguage()
  }, [ checkBrowserLanguage, fetchIntlData ])

  useEffect(() => {
    if (session && !isInitialized) fetchLiteralsData()
  }, [ fetchLiteralsData, isInitialized, session ])

  return (
    <LanguageContext.Provider
      value={{
        locale: language,
        messages,
        languages,
        literals,
        setLocale: handleSetLocale,
        setLanguages,
        setLiterals,
        isInitialized,
        setIsInitialized
      }}
    >
      <IntlProvider locale={ language } messages={ messages }>
        { children }
      </IntlProvider>
    </LanguageContext.Provider>
  )
}

export { LanguageContext, LanguageProvider }
