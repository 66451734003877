import { IStorageService } from '../models'

export const prefix = '@REDS'

class LocalStorageService implements IStorageService {
  public set(key: string, value: string): void {
    try {
      localStorage.setItem(`${ prefix }:${ key }`, value)
    } catch (error) {
      console.error(error)
    }
  }

  public get(key: string): any {
    try {
      const value = localStorage.getItem(`${ prefix }:${ key }`)
      return value ? JSON.parse(value) : undefined
    } catch (error) {
      console.error(error)
    }
  }

  public remove(key: string): void {
    try {
      localStorage.removeItem(`${ prefix }:${ key }`)
    } catch (error) {
      console.error(error)
    }
  }
}

export default new LocalStorageService()
