import styled from 'styled-components'

export default styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  max-width: 210px;

  .status-badge {
    width: 8px;
    height: 8px;
    border-radius: 5px;
    position: absolute;
    top: 0;
    left: 32px;
    border: 1px solid ${ ({ theme }) => theme.colors.white };
    box-sizing: content-box;
  }

  .file {
    overflow: hidden;
    flex-basis: 40px;
    width: 40px;
    height: 40px;
    padding: 0;
    justify-content: center;
    background-color: ${ ({ theme }) => theme.colors.grey.light };
    border-color: ${ ({ theme }) => theme.colors.grey.light };
    margin-bottom: 0;
    
    &.exchange {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 20px;
      cursor: pointer;
    }
    
    > img {
      width: 22px;
      height: 22px;
      max-height: 22px;
    }

    .icon {
      margin: 0;
      font-size: 22px;
    }
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  .io-icon {
    font-size: 24px;
    margin-left: 5px;
    color: #687494;
  }

  &:hover {
    .preview {
      display: flex;
    }
  }

  @media (max-width: 736px) {
    .preview {
      display: flex;
    }
  }
`
