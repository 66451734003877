import axios from 'axios'
import { ISession } from '../models'
import { LocalStorageService } from '../services'
import { useAxios } from './useAxios'

export const useAuth = () => {
  const { handleRequest, error, loading } = useAxios()
  const signIn = async (userName?: string, userDomain?: string, userPassword?: string): Promise<ISession> => {
    const session = await axios.get(
      'https://api.ipify.org'
    ).then(async res => {
      const userIP = res.data
      return await handleRequest('login', 'POST', { userName, userDomain, userPassword, userIP })
    }).catch(async err => {
      console.error(err)
      return await handleRequest('login', 'POST', { userName, userDomain, userPassword, userIP: '' })
    })
    return { ...session, userName, userDomain }
  }

  const signOut = async (userName: string, guid: string): Promise<void> => {
    await handleRequest('logout', 'POST',  { userName, guid })
    LocalStorageService.remove('session')
  }

  const changePassword = async (
    guid: string, oldPassword: string, newPassword: string, domain?: string
  ): Promise<void> => {
    await handleRequest('changepassword', 'POST', { guid, domain, oldPassword, newPassword })
  }
  return { signIn, signOut, changePassword, error, loading }
}
