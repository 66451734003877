import { Button, Toggle } from '@valudio/ui'
import React, { ReactNode } from 'react'
import { useIntl } from 'react-intl'
import Styled from './styles'

interface IProps {
  onSearch: () => void
  onArchiveChange: () => void
  initialArchiveValue: boolean
  isArchiveHidden: boolean
  areActionsDisabled?: boolean
}

const FilterActions: React.FC<IProps> = ({
  onSearch, onArchiveChange, isArchiveHidden, initialArchiveValue, areActionsDisabled
}) => {
  const { formatMessage } = useIntl()
  const archiveToggle: ReactNode = !isArchiveHidden && (
    <div className="archive">
      <span className="toggle-label">{ formatMessage({ id: 'showTracks' }) }</span>
      <Toggle initialValue={ initialArchiveValue } onChange={ onArchiveChange  } isDisabled={ areActionsDisabled }/>
    </div>
  )

  return (
    <Styled className="submit">
      <Button onClick={ onSearch } isDisabled={ areActionsDisabled }>
        { formatMessage({ id: 'search' }) }
      </Button>
      { archiveToggle }
    </Styled>
  )
}

export default FilterActions
