import styled from 'styled-components'

export default styled.section`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  min-width: 100%;
  margin: auto;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    > span {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 6px;

      .track-direction {
        margin: 0 28px;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
`
