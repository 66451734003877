import { ILabelResponse } from '../models'
import { useAxios } from './useAxios'

export const useLanguage = () => {
  const { handleRequest, error, loading } = useAxios()

  const changeLanguage = async (language: string): Promise<void> => {
    await handleRequest('changelanguage', 'POST', { language })
  }
  const fetchIntlData = async (): Promise<ILabelResponse> => {
    return await handleRequest('labels?culture=-1', 'GET')
  }
  return { changeLanguage, fetchIntlData, error, loading }
}
