import styled from 'styled-components'

export default styled.article`
  position: absolute;
  min-width: 340px;
  max-width: 340px;
  width: 100%;
  top: 40px;
  display: none;
  flex-direction: column;
  background: white;
  z-index: 50;
  margin: auto;
  padding: 14px 16px;
  box-shadow: 0 2px 17px 1px rgba(40, 88, 215, 0.16);
  border-radius: 4px;

  + .overlay {
    display: none;
  }

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    
    > img {
      width: 16px;
      height: auto;
      margin-right: 8px;
    }

    .title {
      margin: 0 26px 0 0;
      color: ${ ({ theme }) => theme.colors.typography.darkest };
      font-family: ${ ({ theme }) => theme.fontFamily };
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      word-wrap: anywhere;
    }
  }

  .content {
    .field:not(:last-child) {
      margin: 0 0 5px;
    }
    
    .pdf-report {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      
      > img {
        width: 22px;
        height: 22px;
        cursor: pointer;
      }
      
      > .file {
        background-color: transparent;
        border-color: transparent;
        width: 22px;
        height: 22px;
      }

      > .download {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 0 0 4px;
        width: 22px;
        height: 22px;
        font-size: 22px;
        color: ${ ({ theme }) => theme.colors.typography.medium };
        flex-shrink: 0;
        box-sizing: border-box;
        cursor: pointer;
      }
    }
  }
  
  footer.actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-top: 10px;

    > button {
      margin: 4px;
      flex: 1;
      
      &:first-of-type {
        margin-left: 0;
      }
      
      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  @media (max-width: 736px) {
    position: fixed;
    z-index: 1000;
    top: 30vh;
    right: 0;
    left: 0;
    max-width: 80vw;
    height: auto;
    margin: auto;
    box-shadow: none;
    border-radius: 2px;

    + .overlay {
      z-index: 900;
      display: block;
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      width: 100vw;
      height: 100vh;
      opacity: 0.7;
      background: ${ ({ theme }) => theme.colors.typography.darkest };
    }

    .header {
      .download {
        display: none;
      }
    }
  }
`
