import { Menu as UIMenu, MenuItem } from '@valudio/ui'
import React, { useContext } from 'react'
import { useIntl } from 'react-intl'
import { useLocation, useNavigate } from 'react-router-dom'
import { LanguageContext, NotificationContext, SessionContext } from '../../../context'
import { useAuth, useLanguage } from '../../../hooks'
import { urls } from '../../../routing'

const Menu: React.FC = () => {
  const { formatMessage } = useIntl()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { session, setSession } = useContext(SessionContext)
  const { languages, setLocale } = useContext(LanguageContext)
  const { setNotification } = useContext(NotificationContext)
  const { signOut } = useAuth()
  const { changeLanguage } = useLanguage()
  const isMobileViewWidth = window.screen.width <= 736
  const isHomeActive = pathname.includes(urls.home)
  const isChangePasswordActive = pathname.includes(urls.changePassword)

  const languageItems = languages
    ? languages.map(l => (
        { locale: l.code, label: l.name }
      ))
    : []

  const handleLanguageClick = async (locale: string) => {
    try {
      await changeLanguage(locale)
      setLocale(locale)
    } catch (error) {
      console.error(error)
      setNotification((error as any).message)
    }
  }

  const handleHomeNavigate = () => {
    navigate(urls.home)
  }

  const handleChangePasswordNavigate = () => {
    navigate(urls.changePassword)
  }

  const handleSignOut = async () => {
    if (session) {
      await signOut(session.userName, session.guid)
      setLocale('en-US')
      setSession(null)
    }
  }

  return (
    <UIMenu
      username={ `${ formatMessage({ id: 'logout' }) }`}
      languages={ languageItems }
      onLanguageClick={ handleLanguageClick }
      isHidden={ !session }
      logoSrc={ session ? session.theme.logo : '' }
      onSignOut={ handleSignOut }
      // isExpanded={ isMenuExpanded }
      style={ { width: isMobileViewWidth ? 0 : '64px', zIndex: 1000 } }
    >
      <MenuItem
        onClick={ handleHomeNavigate }
        label={ `${ formatMessage({ id: 'dashboard' }) }` }
        icon="dashboard"
        isActive={ isHomeActive }
      />
      <MenuItem
        onClick={ handleChangePasswordNavigate }
        label={ `${ formatMessage({ id: 'changePassword' }) }` }
        icon="change-password"
        isActive={ isChangePasswordActive }
      />
      <MenuItem
        label={ session && session.userName  }
        icon="user"
        style={{
          marginTop: 'auto',
          pointerEvents: 'none',
          cursor: 'default',
          overflow: 'hidden',
          whiteSpace: 'pre-wrap',
          textOverflow: 'ellipsis',
          wordBreak: 'break-all'
        }}
      />
    </UIMenu>
  )
}

export default Menu
