import { Spinner } from '@valudio/ui'
import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { SessionContext, ThemeContext, NotificationContext } from '../../context'
import { formatLoginTheme, getSubdomain } from '../../helpers/configuration'
import { useConfiguration } from '../../hooks'
import { urls } from '../../routing'
import Styled from './styles'

const Splash: React.FC = () => {
  const navigate = useNavigate()
  const { session, setSession } = useContext(SessionContext)
  const { setNotification } = useContext(NotificationContext)
  const { theme, setTheme } = useContext(ThemeContext)
  const { getLoginTheme } = useConfiguration()

  const fetchLoginTheme = async (): Promise<void> => {
    try {
      const subdomain = getSubdomain()
      const loginTheme = await getLoginTheme(subdomain)
      if (loginTheme) setTheme(formatLoginTheme(loginTheme))
    } catch (error) {
      console.error(error)
      setNotification((error as any).message)
    }
  }

  useEffect(() => {
    fetchLoginTheme()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (theme && !session) navigate(urls.signIn)
    if (session && session.guid) navigate(urls.home)
  }, [ navigate, session, setSession, setTheme, theme ])

  return (
    <Styled>
      <Spinner />
    </Styled>
  )
}

export default Splash
