import { ILabelResponse } from '../../models'
import { BaseService } from './base'

class LanguageService extends BaseService {
  public changeLanguage(language: string): Promise<void> {
    return this.post('changelanguage', { language })
  }

  public fetchIntlData(): Promise<ILabelResponse> {
    return this.get('labels?culture=-1')
  }
}

export default new LanguageService()
