import styled from 'styled-components'

export default styled.section`
  padding-top: 12px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: center;

  .archive {
    display: flex;
    flex-direction: row-reverse;

    .toggle-label {
      margin: 0 50px 0 8px;
    }
  }

  @media (max-width: 736px) {
    flex-direction: column-reverse;
    padding-bottom: 12px;

    .archive {
      width: 100%;
      justify-content: flex-end;

      .toggle-label {
        margin: 0 0 24px 8px;
      }
    }

    > button {
      width: 100%;
    }
  }
`
