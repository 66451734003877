import React from 'react'
import { Status } from '../../../models'
import StyledDiv from './styles'

interface IProps {
  status: Status
}

const StatusSteps: React.FC<IProps> = ({ status }) => {
  const steps = () => {
    const stepItems = []
    for (let i = 0; i < Number(status.value.step); i++) {
      stepItems.push(
        <svg width="22" height="12" viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0.475586 0H17.9124L22.0002 6L17.9124 12H0.475586L4.49947 6L0.475586 0Z"
            fill={ status.value.color }
          />
        </svg>
      )
    }
    for (let i = stepItems.length; i < 5; i++) {
      stepItems.push(
        <svg width="22" height="12" viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <svg width="22" height="12" viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.475586 0H17.9124L22.0002 6L17.9124 12H0.475586L4.49947 6L0.475586 0Z" fill="#E1F2FE"/>
          </svg>
        </svg>
      )
    }
    return stepItems
  }
  return (
    <StyledDiv>
      <header>
        <h4 style={{ color: status.value.color }}>{ status.value.text }</h4>
      </header>
      <p className="steps-container">
        { steps() }
      </p>
    </StyledDiv>
  )
}

export default StatusSteps
