import { Field, Input } from '@valudio/ui'
import React from 'react'
import Styled from './styles'

interface IProps {
  label: string
  placeholder?: string
  onChange?: (value: string) => void
}

const TextFilter: React.FC<IProps> = props => {
  const { label, placeholder, onChange } = props

  return (
    <Styled className="filter">
      <Field label={ label } className="field">
        <Input
          className="input"
          placeholder={ placeholder }
          onChange={ onChange }
        />
      </Field>
    </Styled>
  )
}

export default TextFilter
