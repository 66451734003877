export enum  Language {
  'es-ES',
  'en-US',
  'fr-FR'
}

export interface ILanguage {
  name: string
  code: string
}
