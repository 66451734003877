import React from 'react'
import { ITrackFile } from '../../../../models'
import TrackFile from '../../TrackFile'
import StyledSection from './styles'

interface IProps {
  sender: string
  receiver: string
  inbounds: ITrackFile[]
  outbounds: ITrackFile[]
}

const Exchanges: React.FC<IProps> = ({ sender, receiver, inbounds, outbounds }) => {

  return (
    <StyledSection>
      <div className="inbound">
        {
          inbounds.map( file => (
            <span key={ file.fileId }>
              <TrackFile
                sender={ sender }
                receiver={ receiver }
                className="track-file"
                key={ file.fileId }
                trackFile={ file }
              />
              {
                !outbounds.length
                  ? (
                    <img
                      className="track-direction"
                      src="assets/images/arrow-right.svg"
                      alt="outbound"
                    />
                  ) : null
              }
            </span>
          ))
        }
      </div>
      <div className="outbound">
        {
          outbounds.map( file => (
            <span key={ file.fileId }>
              <img
                className="track-direction"
                src="assets/images/arrow-right.svg"
                alt="outbound"
              />
              <TrackFile
                sender={ sender }
                receiver={ receiver }
                className="track-file"
                key={ file.fileId }
                trackFile={ file }
              />
            </span>

          ))
        }
      </div>
    </StyledSection>
  )
}

export default Exchanges
