import { Button, Field, Input } from '@valudio/ui'
import React, { useContext, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { Menu, PageTitle } from '../../components'
import { SessionContext, UiContext, NotificationContext } from '../../context'
import { useAuth } from '../../hooks'
import { urls } from '../../routing'
import Styled from './styles'

const ChangePassword: React.FC = () => {
  const navigate = useNavigate()
  const { formatMessage } = useIntl()
  const { session } = useContext(SessionContext)
  const { isMenuExpanded, setIsMenuExpanded } = useContext(UiContext)
  const { setNotification } = useContext(NotificationContext)
  const { changePassword } = useAuth()
  const [ oldPassword, setOldPassword ] = useState('')
  const [ newPassword, setNewPassword ] = useState('')
  const [ error, setError ] = useState<Error>(null)

  const toggleResponsiveMenuVisibility = (): void => {
    setIsMenuExpanded(!isMenuExpanded)
  }

  const handleOldPasswordChange = (oldPassword: string): void => {
    setOldPassword(oldPassword)
    setError(null)
  }


  const handleNewPasswordChange = (newPassword: string): void => {
    setNewPassword(newPassword)
    setError(null)
  }

  const handleChangePassword = async (): Promise<void> => {
    const { guid, userDomain } = session

    try {
      await changePassword(guid, oldPassword, newPassword, userDomain)
      setError(null)
    } catch (error) {
      console.error(error)
      setError(error as Error)
      setNotification((error as Error).message)
    } finally {
      setOldPassword('')
      setNewPassword('')
    }
  }

  useEffect(() => {
    if (!session) navigate(urls.splash)
  }, [ session, navigate ])

  return (
    <>
      <Menu />
      <Styled>
        <PageTitle onClick={ toggleResponsiveMenuVisibility }>
          { formatMessage({ id: 'changePassword' }) }
        </PageTitle>
        <section className="container">
          <Field label={ formatMessage({ id: 'oldPassword'  }) } className="field">
            <Input
              className="input"
              type="password"
              placeholder={ formatMessage({ id: 'introduceOldPassword'  }) }
              onChange={ handleOldPasswordChange }
            />
          </Field>
          <Field label={ formatMessage({ id: 'newPassword'  }) } className="field">
            <Input
              className="input"
              type="password"
              placeholder={ formatMessage({ id: 'introduceNewPassword'  }) }
              onChange={ handleNewPasswordChange }
            />
          </Field>
          <Button className="button" onClick={ handleChangePassword } isDisabled={ !!error }>
            { formatMessage({ id: 'updatePassword' }) }
          </Button>
        </section>
      </Styled>
      <div
        className={`menu-overlay ${ isMenuExpanded ? 'visible' : '' }`}
        onClick={ toggleResponsiveMenuVisibility }
      />
    </>
  )
}

export default ChangePassword

