import axios,  { AxiosError, AxiosRequestConfig, Method } from 'axios'
import { useContext, useState } from 'react'
import { SessionContext } from '../context'
import { ISession } from '../models'
import { LocalStorageService } from '../services'

const apiUrl = process.env.REACT_APP_API_URL

export const useAxios = () => {
  const { session, setSession } = useContext(SessionContext)
  const [ error, setError ] = useState(null)
  const [ loading, setLoading ] = useState(false)

  const handleRequest = async (endpoint: string, method: Method, body?: any) => {
    setLoading(true)

    const data = session && session.guid
      ? { ...body, userId: session.userName, guid: session.guid }
      : { ...body }
    const config: AxiosRequestConfig = {
      auth: { username: 'valudio', password: 'Xilofono23%' },
      baseURL: apiUrl,
      headers: {
        'Content-Type': 'application/json'
      },
      method
    }

    switch (method) {
      case 'PUT':
        return axios.put(endpoint, data, config)
          .then(response => handleResponse(response))
          .catch(error => handleResponseError(error))
      case 'POST':
        return axios.post(endpoint, data, config)
          .then(response => handleResponse(response))
          .catch(error => handleResponseError(error))
      case 'DELETE':
        return axios.delete(endpoint, config)
          .then(response => handleResponse(response))
          .catch(error => handleResponseError(error))
      case 'PATCH':
        return axios.patch(endpoint, data, config)
          .then(response => handleResponse(response))
          .catch(error => handleResponseError(error))
      default:
        if (session && session.guid) {
          return axios.get(endpoint, { ...config, params: { userId: session.userName, guid: session.guid }})
            .then(response => handleResponse(response))
            .catch(error => handleResponseError(error))
        } else {
          return axios.get(endpoint, config)
            .then(response => handleResponse(response))
            .catch(error => handleResponseError(error))
        }
    }
  }

  const handleResponse = (response: any): any => {
    setError(null)
    setLoading(false)
    return response.data
  }

  const handleResponseError = async (error: AxiosError, session?: ISession): Promise<any> => {
    setError(error)
    if (error.response && error.response.status === 401) {
      const hasCredentials = !!session
      const { userName, guid } = hasCredentials && session
      console.log('Signing out')
      if ( hasCredentials ) await axios.post('logout', { userName, guid })
      LocalStorageService.remove('session')
      setSession(null)
      const errorMsg = 'User credentials invalid'
      throw errorMsg
    } else {
      throw error.response && error.response.statusText ? error.response.statusText : error.message
    }
  }

  return { handleRequest, error, loading }
}
