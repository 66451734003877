import { Provider as ValudioUiProvider } from '@valudio/ui'
import React, { PropsWithChildren, useContext } from 'react'
import {
  LanguageContext,
  LanguageProvider,
  NotificationProvider,
  SessionProvider,
  ThemeContext,
  ThemeProvider,
  UiProvider
} from '../../../context'
import { Language } from '../../../models'

const Provider: React.FC<PropsWithChildren<any>> = ({ children }) => {
  const { theme } = useContext(ThemeContext)
  const { locale } = useContext(LanguageContext)
  const languageFallback = locale in Language ? locale : 'en-US'

  return (
    <SessionProvider>
      <ThemeProvider>
        <LanguageProvider>
          <ValudioUiProvider theme={ theme } language={ languageFallback }>
            <UiProvider>
              <NotificationProvider>
                { children }
              </NotificationProvider>
            </UiProvider>
          </ValudioUiProvider>
        </LanguageProvider>
      </ThemeProvider>
    </SessionProvider>
  )
}

export default Provider
