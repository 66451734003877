import React, { createContext, PropsWithChildren, useState } from 'react'
import { sleep } from '../helpers/utils'

interface INotificationContext {
  notification?: string
  setNotification: (message: string) => Promise<void>
}

const NotificationContext = createContext<INotificationContext>({
  notification: undefined,
  setNotification: () => undefined
})

const NotificationProvider: React.FC<PropsWithChildren<any>> = ({ children }) => {
  const [ value, setValue ] = useState(undefined)

  const handleSetNotification = async (message: string) => {
    setValue(message)
    await sleep(4000)
    setValue(undefined)
  }

  return (
    <NotificationContext.Provider value={{ notification: value, setNotification: handleSetNotification }}>
      { children }
    </NotificationContext.Provider>
  )
}

export { NotificationContext, NotificationProvider }
