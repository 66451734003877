import React, { createContext, PropsWithChildren, useEffect, useState } from 'react'
import { formatTheme } from '../helpers/session'
import { ITheme } from '../models'
import { LocalStorageService } from '../services'

export interface IThemeContext {
  theme?: ITheme
  setTheme: (newTheme?: ITheme) => void
}

const ThemeContext = createContext<IThemeContext>({
  theme: undefined,
  setTheme: () => undefined
})

const ThemeProvider: React.FC<PropsWithChildren<any>> = ({ children }) => {
  const [ theme, setTheme ] = useState(formatTheme())

  useEffect(() => {
    const activeSession = LocalStorageService.get('session')
    if (activeSession) {
      setTheme(formatTheme(activeSession.theme))
    }
  }, [])

  return (
    <ThemeContext.Provider
      value={{ theme, setTheme }}
    >
      { children }
    </ThemeContext.Provider>
  )
}

export { ThemeProvider, ThemeContext }
