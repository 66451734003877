import styled from 'styled-components'

export default styled.article`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f2f3f7;

  .container {
    width: 960px;
    height: 584px;
    background-color: white;
    border-radius: 2px;
    box-shadow: 0 2px 16px 1px rgba(66,74,97,0.15);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;

    .column {
      box-sizing: border-box;

      &.form {
        flex: 1;
        padding: 60px 56px;
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        align-items: center;
        position: relative;

        .logo {
          align-self: flex-start;
          margin-bottom: 40px;
          max-width: 50%;

          img {
            height: auto;
            width: 100%;
          }
        }

        .field {
          width: 100%;

          > .input {
            margin-bottom: 22px;
            width: 100%;
            max-width: 100%;
          }
        }

        .button {
          position: absolute;
          bottom: 60px;
        }

        .error {
          width: 100%;
          min-height: 40px;
          border-radius: 4px;
          background: ${ ({ theme }) => theme.colors.system.error }40;
          color: ${ ({ theme }) => theme.colors.system.error };
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          padding: 0 8px;
          box-sizing: border-box;
          font-size: 12px;

          .icon {
            color: ${ ({ theme }) => theme.colors.system.error };
            font-size: 24px;
            margin-right: 8px;
          }
        }
      }

      &.image {
        flex-basis: 448px;
        background-image: url('assets/images/login-background.svg');
      }
    }

    @media (max-width: 736px) {
      width: 100%;
      height: 100%;
      max-height: 100vh;

      .column {
        &.form {
          padding: 30px;

          .logo {
            max-width: 90%;
            align-self: center;
          }

          .button {
            position: relative;
            margin-top: 40px;
            bottom: 0;
          }
        }

        &.image {
          display: none;
        }
      }

    }
  }
`
