import React from 'react'
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import { Notification } from './components'
import { routes } from './routing'

function App() {
  const pages = routes.map((route, i) => {
    // if (route.isPrivate) {
    //   return <PrivateRoute key={ i } path={ route.url } component={ route.content } />
    // }
    return <Route key={ i } path={ route.url } element={ <route.content /> } />
  })
  return (
    <BrowserRouter>
      <Notification />
      <Routes>
        { pages }
      </Routes>
    </BrowserRouter>
  )
}

export default App
