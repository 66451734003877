import styled from 'styled-components'

export default styled.div`
  display: flex;
  flex-direction: row;

  .label {
    flex: 1;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${ ({ theme }) => theme.colors.typography.medium };

    &::after {
      content: ':';
    }
  }

  .value {
    flex: 1;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${ ({ theme }) => theme.colors.typography.darkest };
  }
`
