import axios from 'axios'
import { LocalStorageService } from '..'
import { ISession } from '../../models'
import { BaseService } from './base'

class AuthenticationService extends BaseService {
  public async signIn(userName: string, userDomain: string, userPassword: string): Promise<ISession> {
    const session = await axios.get(
      'https://api.ipify.org'
    ).then(async res => {
      const userIP = res.data
      return await this.post('login', { userName, userDomain, userPassword, userIP })
    }).catch(async err => {
      console.error(err)
      return await this.post('login', { userName, userDomain, userPassword, userIP: '' })
    })
    return { ...session, userName, userDomain }
  }

  public async signOut(userName: string, guid: string): Promise<void> {
    await this.post('logout', { userName, guid })
    LocalStorageService.remove('session')
  }

  public async changePassword(guid: string, oldPassword: string, newPassword: string, domain?: string): Promise<void> {
    await this.post('changepassword', { guid, domain, oldPassword, newPassword })
  }
}

export default new AuthenticationService()
