import { Button, Icon } from '@valudio/ui'
import React, { CSSProperties } from 'react'
import { useIntl } from 'react-intl'
import { downloadBase64 } from '../../../helpers/configuration'
import { IFile, ITrackFile } from '../../../models'
import Field from './Field'
import Styled from './styles'

interface IProps {
  trackFile: ITrackFile
  file: IFile
  fileReport?: IFile
  sender: string
  receiver: string
  isHidden?: boolean
  className?: string
  onOpenClick?: () => void
  onClose?: () => void
  onOpenPdf?: () => void
  onDownloadPdf?: () => void
}

const FilePreview: React.FC<IProps> = ({
  file, fileReport, sender, receiver, trackFile, isHidden, className, onOpenClick, onClose, onOpenPdf, onDownloadPdf
}) => {
  const { formatMessage } = useIntl()
  const buttonStyles: CSSProperties = {
    height: '28px',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '10px',
    width: 'auto'
  }

  if (isHidden) return null
  return (
    <>
      <Styled className={ className || '' }>
        <section className="header">
          <img src={ trackFile.icon } alt={ trackFile.extension } />
          <span className="title">{ file.name }</span>
        </section>
        <section className="content">
          <Field className="field" label={ formatMessage({ id: 'direction' }) }>
            { `${ formatMessage({ id: 'from' }) } ${ sender } ${ formatMessage({ id: 'to' }) } ${ receiver }` }
          </Field>
          <Field className="field" label={ formatMessage({ id: 'status' }) }>
            { trackFile.status.value.text }
          </Field>
          {
            trackFile.pdfAvailable && fileReport
              ? (
                <Field className="field" label={ formatMessage({ id: 'report' }) }>
                  <div className="pdf-report">
                    <img
                      className="pdf"
                      src="https://redsimages.redsol.fr/fileicons/pdf.png"
                      alt="PDF"
                      onClick={ onOpenPdf }
                    />
                    <Icon
                      className="download"
                      icon="download"
                      onClick={ onDownloadPdf }
                    />
                  </div>
                </Field>
              )
              : null
          }
          <Field className="field" label={ formatMessage({ id: 'channel' }) }>{ trackFile.channel }</Field>
          <Field className="field" label={ formatMessage({ id: 'size' }) }>{ trackFile.size }</Field>
          <Field className="field" label={ formatMessage({ id: 'format' }) }>{ trackFile.format }</Field>
          <Field className="field" label={ formatMessage({ id: 'subFormat' }) }>{ trackFile.subFormat }</Field>
          <Field className="field" label={ formatMessage({ id: 'comments' }) }>{ trackFile.comments }</Field>
          <Field className="field" label={ formatMessage({ id: 'updateDate' }) }>{ trackFile.updateDate }</Field>
        </section>
        <footer className="actions">
          {/*<Button type="secondary" style={ buttonStyles } onClick={ onClose }>*/}
          {/*  { formatMessage({ id: 'cancel' }) }*/}
          {/*</Button>*/}
          <Button type="secondary" style={ buttonStyles } onClick={ onOpenClick } >
            { formatMessage({ id: 'openTheDoc' }) }
          </Button>
          <Button
            type="primary" style={ buttonStyles }
            onClick={ downloadBase64.bind(undefined, file) }
            isHidden={ !file.data }
          >
            { formatMessage({ id: 'downLoad' }) }
          </Button>
        </footer>
      </Styled>
      <div className="overlay" onClick={ onClose }/>
    </>
  )
}

export default FilePreview
