import styled from 'styled-components'

export default styled.section`
  background: ${ ({ theme }) => theme.colors.grey.light };
  padding: 32px;
  flex: 1;

  > article {
    margin: 0 0 20px 0;
  }

  .cell {
    justify-content: center;

    .archive-icon {
      font-size: 20px;
      color: ${ ({ theme }) => theme.colors.typography.light };
      cursor: pointer;

      &.visible {
        color: ${ ({ theme }) => theme.colors.primary.medium };
      }
    }

    .sender-receiver {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: stretch;
      margin-top: 16px;
      margin-bottom: 16px;

      > img {
        width: 10px;
        height: auto;
        margin: 2px 0;
        align-self: center;
      }

      > span {
        text-align: center;
        font-weight: bold;
        line-height: 20px;
        color: ${ ({ theme }) => theme.colors.typography.dark };
      }
    }

    .track-flow {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: stretch;
      align-items: center;
      margin: 3px 0;

      .track-direction {
        margin: 0 28px;
      }

      .empty-block {
        width: 34px;
        height: 34px;
      }
    }

    .status {
      align-self: center;
    }

    .dates {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;

      p {
        margin: 0 0 4px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        color: ${ ({ theme }) => theme.colors.typography.medium };

        span.date {
          color: ${ ({ theme }) => theme.colors.typography.dark };
          text-align: left;
          margin-right: 10px;
        }
      }
    }
  }

  @media (max-width: 736px) {
    padding: 0 8px 8px;

    > article {
      margin-bottom: 8px;

      > .header {
        display: none;
      }
    }


    .content {
      > article {
        box-shadow: 0 2px 6px 0 rgba(66,74,97,0.12);
        flex-wrap: wrap;
        padding: 12px 16px 16px;

        &:first-of-type {
          margin-top: 8px;
        }

        .cell {
          margin: 0 0 16px;

          &:first-of-type {
            order: 3;
            text-align: right;
          }
          &:last-of-type {
            order: 1;
            align-self: center;
            height: 22px;
            justify-content: center;
          }
          &:nth-child(2) {
            order: 2;
            align-self: center;
            text-align: center;
            font-size: 16px;
          }
          &:nth-child(3) {
            order: 4;
            font-size: 16px;
          }
          &:nth-child(4) {
            order: 7;
            margin-bottom: 0;
            max-width: 100%;

            > div {
              max-width: 100%;
            }
          }
          &:nth-child(5) {
            order: 5;
          }
          &:nth-child(6) {
            order: 6;
          }

          .track-file {
            max-width: 100%;
          }
        }
      }
    }
  }
`
